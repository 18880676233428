export default async function fetchGet(destination: string): Promise<Response> {
  const token = process.env.STRAPI_API;
  const host = "*";
  const origin = "*";
  const method = "GET";
  const options = {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      //   Origin: origin,
      //   Host: host,
    },
  };
  return await fetch(destination, options);
}
