import { fetch, fetchInternal } from "../../services/API";

export const FOOTER = fetch.endpoint("/footer");
export const TOP_NAVIGATION = fetch.endpoint("/top-navigation");
export const MEDIA_ASSETS = fetch.endpoint("/media-assets");
export const PACKAGE_BY_NIGHTS = fetch.endpoint("/package-by-day");
export const QUOTE_REQUESTS_MQ = fetchInternal.endpoint("/quote-requests/mq");
export const QUOTE_REQUESTS_MQ_EXTERNAL = fetch.endpoint("/quote-requests/mq");
export const HOME_PAGE_DETAILED = fetch.endpoint("/home-page-detailed");
export const TAG_PACKAGE_THEME = fetch.endpoint("/package-theme-tags");
export const MENUS = fetch.endpoint("/menus?nested");
export const SEARCH_MANAGEMENT = fetch.endpoint("/search-management/detailed");
export const USP = fetch.endpoint("/get-usps");
export const RAQ_FORM = fetch.endpoint("/raq-form/get-types");
export const USER_PREF_POST = fetchInternal.endpoint("/user-preferences/post/");
export const USER_PREF_POST_EXTERNAL = fetch.endpoint(
  "/user-preferences/post/"
);
export const USER_PREF_SYNC = fetchInternal.endpoint("/user-preferences/sync/");
export const USER_PREF_SYNC_EXTERNAL = fetch.endpoint(
  "/user-preferences/sync/"
);
export const REDIRECTS = fetch.endpoint("/redirect");
export const GET_LANDING_ITEMS = fetch.endpoint("/landing-pages/get-items/");

export const DETAILED = {
  TCS: fetch.endpoint("/travel-consultant/detailed/"),
  PACKAGES: fetch.endpoint("/packages/detailed/"),
  ACTIVITIES: fetch.endpoint("/activities/detailed/"),
  ACCOMMODATIONS: fetch.endpoint("/accommodations/detailed/"),
  LOCATIONS: fetch.endpoint("/locations/detailed/"),
  LANDING_PAGES: fetch.endpoint("/landing-pages/detailed/"),
  PAGES: fetch.endpoint("/pages/detailed/"),
  ARTICLES: fetch.endpoint("/articles/detailed/"),
};

export const SLUGS = {
  TCS: fetch.endpoint("/travel-consultant/slugs/get"),
  PACKAGES: fetch.endpoint("/packages/slugs/get"),
  ACTIVITIES: fetch.endpoint("/activities/slugs/get"),
  ACCOMMODATIONS: fetch.endpoint("/accommodations/slugs/get"),
  LANDING_PAGES: fetch.endpoint("/landing-pages/slugs/get"),
  LOCATIONS: fetch.endpoint("/locations/slugs/get"),
  PAGES: fetch.endpoint("/pages/slugs/get"),
  ARTICLES: fetch.endpoint("/articles/slugs/get"),
  CONTACTUS: fetch.endpoint("/contact-us?populate=*"),
};

export const SITEMAP_SLUGS = {
  TCS: fetch.endpoint("/sitemap/travel"),
  PACKAGES: fetch.endpoint("/sitemap/packages"),
  ACTIVITIES: fetch.endpoint("/sitemap/activities"),
  ACCOMMODATIONS: fetch.endpoint("/sitemap/stay"),
  LANDING_PAGES: fetch.endpoint("/sitemap/landing_pages"),
  LOCATIONS: fetch.endpoint("/sitemap/locations"),
  PAGES: fetch.endpoint("/sitemap/pages"),
  ARTICLES: fetch.endpoint("/sitemap/articles"),
};
