import getConfig from "next/config";

// we are removing the double slashes in case somehow they appear in the url.

const { publicRuntimeConfig } = getConfig();
export const fetch = {
  endpoint: function (url) {
    return "https://" + publicRuntimeConfig.CMS_URL + url;
  },
};

export const fetchInternal = {
  endpoint: function (url) {
    return publicRuntimeConfig.WEB_URL_NOCF + "api" + url;
  },
};
