import ServerErrorComponent from "@components/pages/page/components/ServerErrorComponent";
import { GetStaticProps } from "next";

const ServerErrorPage = (): JSX.Element => {
  return <ServerErrorComponent cf={false} />;
};

export default ServerErrorPage;

export const getStaticProps: GetStaticProps = async () => {
  /*
  const {
    footer,
    mediaAssets,
    searchManagementRes,
    topNavigation,
    menus,
    usp,
  } = await baseApiCalls();
*/
  return {
    props: {
      //footer,
      //mediaAssets,
      //topNavigation,
      //menus,
      //usp,
      //searchManagement: searchManagementRes,
    },
    revalidate: Number(process.env.REVALIDATE_TIMER),
  };
};
