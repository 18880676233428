import {
  MEDIA_ASSETS,
  MENUS,
  SEARCH_MANAGEMENT,
  TOP_NAVIGATION,
} from "@components/home/routes";
import fetchGet from "@services/api/fetchGet";
import { IDefaultPagePropsResponse } from "../types/commonResponses";

async function baseApiCalls(): Promise<IDefaultPagePropsResponse> {
  //const footerData = await fetchGet(FOOTER);
  //const footer = await footerData.json();

  const mediaAssetsData = await fetchGet(MEDIA_ASSETS);
  const mediaAssets = await mediaAssetsData.json();

  const searchManagementData = await fetchGet(SEARCH_MANAGEMENT);
  const searchManagementRes = await searchManagementData.json();

  const topNavigationData = await fetchGet(TOP_NAVIGATION);
  const topNavigation = await topNavigationData.json();

  const menusData = await fetchGet(MENUS);
  const menus = await menusData.json();

  //const uspData = await fetchGet(USP);
  //const usp = await uspData.json();

  return {
    footer: null,
    mediaAssets,
    searchManagementRes,
    topNavigation,
    menus,
    usp: null,
  };
}
export default baseApiCalls;
